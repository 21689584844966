import "core-js/modules/es.regexp.exec";import "core-js/modules/es.string.replace";
import { homePage } from '@/settings';

export default {
  created: function created() {
    var firstRoute = this.$store.state.permission.addRoutes[3];

    var path = firstRoute.path + '/' + firstRoute.children[0].path;

    this.$router.replace({ path: path || '/' });
  },
  render: function render(h) {
    return h(); // avoid warning message
  } };